import { NavLink } from "react-router-dom";
import Styles from "./Header.module.scss";

export default function Header() {
    return <header className={Styles.header}>
        <div className={Styles.aligner}>
            <a id={Styles.title} href="/">
                <h1><span>Deepak</span> <strong>Penaganti</strong></h1>
            </a>
            <nav className={Styles.nav}>
                <a href={"/#"}>
                    Me
                </a>
                <a href={"/#work"}>
                    Work
                </a>
                <a href={"/#more"}>
                    More
                </a>
            </nav>
        </div>
    </header>
}