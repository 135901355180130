import Header from './components/Header/Header';
import Styles from './App.module.scss';

function App() {
  return (<>
    <Header />
    <main className={Styles.main}>
      <div className={Styles.firstView}>
        <div className={Styles.aboutMe}>
          <h4>[Who I am]</h4>
          <h2>
            Inclusive & Accessible<br />
            Web Developer<br />
            With Sense of<br />
            User Experience<br />
          </h2>
        </div>
        <div className={Styles.imageContainer}>
          {/* <img className={Styles.image} src="/assets/images/profile.jpg" alt='Deepak Penaganti' /> */}
          <div className={Styles.image} />
        </div>
      </div>
      <div id='work' className={Styles.firstView}>
        <div className={Styles.aboutMe}>
          <h4>{"{My Work}"}</h4>
          <h2>
            UI/UX Developer
          </h2>
        </div>
        <div className={Styles.imageContainer}></div>
      </div>
    </main>
  </>
  );
}

export default App;
